.match-item {

    .match-top {
        display: flex;
        align-items: center;

        .datetime {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            color: var(--content-color);
            margin-top: 40px;

            &>div {
                text-align: center;

                @media screen and (max-width: 601px) {
                    font-size: 11px;
                }
            }

            .time {
                background: var(--backdrop-bg);
                font-size: 22px;
                font-weight: 700;
                padding: 4px 13px;
                margin: 6px 0;
                border-radius: 4px;

                @media screen and (max-width: 601px) {
                    font-size: 17px;
                    padding: 4px 10px;
                }
            }
        }

        .team {
            width: 38%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 2.5em;

            &:hover {
                cursor: pointer;
            }

            @media screen and (max-width: 601px) {
                padding-top: .5em;
            }

            .emb {
                width: 150px;
                border-radius: 5px;
                background: rgba(0, 0, 0, .17);
                height: 90px;
                box-sizing: border-box;
                padding: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                @media screen and (max-width: 601px) {
                    width: 120px;
                    height: 70px;
                    border-radius: 20px;
                }

                img {
                    max-height: 80px;

                    @media screen and (max-width: 601px) {
                        max-height: 56px;
                    }
                }
            }

            .name {
                font-weight: 500;
                margin-top: 8px;
                color: var(--content-color);
                font-size: 15px;
                max-width: 90%;

                @media screen and (max-width: 601px) {
                    font-size: 14px;
                    text-align: center;
                    line-height: 1.2;
                }
            }
        }

        .info {
            width: 24%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .scores {
                font-size: 56px;
                color: #fff;

                @media screen and (max-width: 601px) {
                    font-size: 34px;
                }
            }
        }
    }

    .container-nav {
        margin-top: 1em;
    }

    .events {
        color: var(--content-color);
        padding-top: 1em;

        .event {
            display: flex;
            position: relative;
            min-height: 50px;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            overflow: hidden;

            .names {
                transition: all .4s ease-in-out;

                &:hover {
                    cursor: pointer;
                    opacity: .78;
                }
            }

            @media screen and (max-width: 601px) {
                font-size: 12px;

                .names {
                    line-height: 1.1;
                }

                .secondary {
                    margin-top: 3px;
                }
            }

            &.home {
                align-items: flex-end;
                padding-right: calc(50% + 52px);
                padding-left: 1em;

                .info {
                    justify-content: flex-start;
                }

                .names {
                    text-align: right;
                }
            }

            &.away {
                padding-left: calc(50% + 55px);
                padding-right: 1em;

                .info {
                    justify-content: flex-end;
                }
            }

            &:nth-child(even) {
                background: rgba(255, 255, 255, .02);
            }

            .info {
                width: 80px;
                position: absolute;
                left: calc((100% - 80px)/2);
                display: flex;
                align-items: center;
                color: #fff;

                .icon {
                    width: 34px;
                    height: 34px;
                    left: 23px;
                    position: absolute;
                    background-size: 22px;
                    background-position: center;
                    background-repeat: no-repeat;

                    &::before {
                        content: '';
                        position: absolute;
                        background: rgba(0, 0, 0, .15);
                        border-radius: 50%;
                        border: 1px solid rgba(255, 255, 255, .09);
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                    }

                    &.goal {
                        background-image: url('../../assets/img/goal.png');
                    }

                    &.owngoal {
                        background-image: url('../../assets/img/owngoal.png');
                    }

                    &.yc {
                        background-image: url('../../assets/img/yellow.png');
                    }

                    &.rc {
                        background-image: url('../../assets/img/red.png');
                    }

                    &.penalty {
                        background-image: url('../../assets/img/penalty.png');
                    }

                    &.missedPenalty {
                        background-image: url('../../assets/img/penalty.png');

                        &::after {
                            content: '';
                            width: 3px;
                            height: 22px;
                            transform: rotate(45deg);
                            background: #d93939;
                            position: absolute;
                            left: 16px;
                            top: 6px;
                        }
                    }
                }
            }

            .secondary {
                font-size: 12px;
                opacity: .6;
            }
        }
    }

    .rosters {
        display: flex;
        justify-content: space-between;
        color: var(--content-color);
        padding-top: 1em;

        .roster-list {
            width: calc(50% - 1em);

            .roster-item {
                min-height: 40px;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                transition: all 0.4s ease-in-out;

                &:hover {
                    opacity: .78;
                    cursor: pointer;
                }

                @media screen and (max-width: 601px) {
                    font-size: 12px;
                }

                &:nth-child(even) {
                    background: linear-gradient(to right, #ffffff09, 70%, transparent);
                }

                .num {
                    width: 34px;
                    height: 34px;
                    background: rgba(0, 0, 0, .15);
                    border-radius: 50%;
                    border: 1px solid rgba(255, 255, 255, .09);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:first-child {

                .roster-item {
                    flex-direction: row-reverse;
                    padding-left: 1em;
                    text-align: right;

                    .num {
                        margin-left: 12px;
                    }
                }
            }

            &:last-child {

                .roster-item {
                    padding-right: 1em;

                    .num {
                        margin-right: 12px;
                    }

                    &:nth-child(even) {
                        background: linear-gradient(to left, #ffffff09, 70%, transparent);
                    }
                }
            }
        }
    }

    .content-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em 1em;
        color: var(--content-color);

        .section {
            width: 100%;

            .section-grid-card {
                display: flex;
                align-items: center;

                .role {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, .15);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--content-color);
                        opacity: .5;
                        font-size: 22px;
                    }
                }

                .person {
                    padding-left: 1em;

                    div:first-child {
                        opacity: .78;
                        font-weight: 500;
                        font-size: 11px;
                    }
                }
            }
        }
    }

    .broadcast {
        margin: 1em;
        min-height: 100px;
        background: rgba(0, 0, 0, .12);
        border-radius: 6px;
        overflow: hidden;

        @media screen and (max-width: 601px) {
            margin: 0.5em;
        }
    }
}
