.team-top {
    position: absolute;
    display: flex;
    height: 100px;
    left: 140px;
    top: -110px;
    transform: translateY(-150px);
    transition: all 0.5s ease-in-out;

    &.shown {
        transform: translateY(0);
    }

    .emblem {
        height: 100px;

        img {
            height: 100px;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-left: 1.5em;
        padding-bottom: 1em;

        .name {
            color: #fff;;
            font-weight: 500;
            font-size: 24px;
            line-height: 1.2;
        }

        .settlement {
            font-size: 15px;
            line-height: 1;
            color: var(--content-color);
            opacity: .88;
        }
    }

    .form {
        display: flex;
        margin-bottom: 9px;
        opacity: .68;

        span {
            width: 14px;
            height: 14px;
            border: 1px solid;
            border-color: var(--root-bg);
            border-radius: 5px;
            background: #fff;
            margin-right: 3px;
            background: #1DE9B6;

            &.draw {
                background: #FC0;
            }

            &.lost {
                background: #FE4849;
            }
        }
    }

    @media screen and (max-width: 601px) {
        height: 60px;
        top: -60px;
        left: 110px;
        align-items: center;

        .emblem {
            height: 54px;

            img {
                height: 44px;
            }
        }

        .info {
            padding-bottom: .7em;
            padding-left: .75em;

            .name {
                font-size: 16px;
                white-space: nowrap;
            }

            .settlement {
                font-size: 11px;
            }
        }

        .form {
            margin-bottom: 3px;

            span {
                width: 10px;
                height: 10px;
                border-radius: 3px;
            }
        }
    }
}

.team {
    overflow: hidden;

    .team-content {

        @media screen and (min-width: 602px) {
            margin: 1em;
        }
    }
}

.squad-list {

    .squad-select {
        text-align: center;
        padding: 1em;

        button {

            span {
                display: flex;
                align-items: center;
            }
        }
    }

    .list {

        .squad-player {
            height: 32px;
            box-sizing: border-box;
            padding: 0 1em;
            align-items: center;
            display: flex;
            color: var(--content-color);
            background: linear-gradient(to right, transparent ,#ffffff12, 50%, transparent);
            transition: all .4s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .78;
            }

            &:nth-child(even) {
                //background: linear-gradient(to right, #ffffff12, transparent, 50%, #ffffff12);
                background: none;
            }

            .name {
                width: 236px;

                @media screen and (max-width: 601px) {
                    width: 150px;
                    font-size: 12px;
                }
            }

            .num {
                width: 26px;
                height: 26px;
                background: rgba(0, 0, 0, .15);
                border-radius: 50%;
                font-size: 12px;
                border: 1px solid rgba(255, 255, 255, .09);
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: .75em;

                @media screen and (max-width: 601px) {
                    width: 22px;
                    height: 22px;
                    font-size: 10px;
                    margin-right: .5em;
                }
            }

            .squad-stats {
                width: calc(100% - 230px);
                display: flex;

                @media screen and (max-width: 601px) {
                    width: calc(100% - 170px);
                }

                .squad-stats-value {
                    width: calc(100%/6);
                    text-align: center;

                    span {
                        position: relative;

                        @media screen and (max-width: 601px) {
                            font-size: 12px;
                        }
                    }

                    span::before {
                        content: '';
                        display: block;
                        width: 17px;
                        height: 17px;
                        background-size: 17px;
                        position: absolute;
                        left: -22px;
                        opacity: .5;

                        @media screen and (max-width: 601px) {
                            width: 13px;
                            height: 13px;
                            background-size: 13px;
                            left: -15px;
                            top: 0px;
                        }
                    }

                    &.pld span::before {
                        background-image: url('../../assets/img/icons/kick-off.png');
                    }

                    &.goal span::before {
                        background-image: url('../../assets/img/icons/goal.png');
                    }

                    &.assist span::before {
                        background-image: url('../../assets/img/icons/assist.png');
                    }

                    &.mvp span::before {
                        background-image: url('../../assets/img/icons/mvp.png');
                    }

                    &.yc span::before {
                        background-image: url('../../assets/img/icons/yc.png');
                    }

                    &.rc span::before {
                        background-image: url('../../assets/img/icons/rc.png');
                    }
                }
            }
        }
    }
}
