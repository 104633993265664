.match-prepend-date {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .75em;
}

.calendar-section_top {
    background: var(--backdrop-bg);
    display: inline-block;
    width: auto;
    padding: 3px 13px 4px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 6px;
    border-radius: 4px;
    opacity: .85;

    &.muted {
        opacity: .7;
    }

    @media screen and (max-width: 601px) {
        font-size: 12px;
        margin-bottom: 4px;
        padding: 2px 9px;
    }
}

.calendar-section_match {
    display: flex;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1em;
    align-items: center;
    margin-bottom: 7px;
    color: var(--content-color);
    position: relative;
    transition: all .35s ease-in-out;

    @media screen and (min-width: 601px) {

        &:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
    }

    @media screen and (max-width: 601px) {
        padding: 0 .35em;
        flex-wrap: wrap;
        height: auto;

        background: rgba(0, 0, 0, .23);
        border-radius: 7px;
        margin: .35em .25em;
        width: calc(100% - .5em);
        border: 1px solid rgba(255, 255, 255, .05);
        box-shadow: 0px 0px 15px 1px rgba(34, 60, 80, 0.2);
    }

    &:nth-child(odd) {
        background: rgba(0, 0, 0, .23);
    }

    .location {
        width: 20%;
        font-size: 12px;

        @media screen and (max-width: 601px) {
            font-size: 11px;
            position: absolute;
            right: 0;
            width: 30%;
        }

        @media screen and (max-width: 375px) {
            font-size: 10px;
        }
    }

    .teams {
        width: 70%;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .team {
            width: 50%;
            display: flex;
            align-items: center;

            i.emb {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                overflow: hidden;
                border-radius: 2px;
                margin-right: .75em;
                background: rgba(0, 0, 0, .11);

                img {
                    height: 18px;
                    width: auto;
                }
            }

            span {
                line-height: 1.2;

                @media screen and (max-width: 601px) {
                    white-space: nowrap;
                    width: calc(100% - (22px + .75em));
                    overflow: hidden;
                }
            }

            &:first-child {
                flex-direction: row-reverse;

                @media screen and (min-width: 601px) {
                    i.emb {
                        margin-right: unset;
                        margin-left: .75em;
                    }
                }

                @media screen and (max-width: 601px) {
                    flex-direction: row;
                }

                span {
                    text-align: right;

                    @media screen and (max-width: 601px) {
                        text-align: left;
                    }
                }
            }
        }

        .vs {
            width: 30%;
            text-align: center;
            font-weight: 700;
            font-size: 17px;

            span {
                background: rgba(255, 255, 255, 0.07);
                color: #fff;
                border-color: rgba(255, 255, 255, 0.09);
                padding: 3px 7px;
                border-radius: 3px;
            }
        }

        @media screen and (max-width: 601px) {
            flex-wrap: wrap;
            position: relative;
            padding-left: 60px;
            padding-right: 10px;

            .team {
                width: 100%;
                justify-content: flex-start !important;
                height: 25px;
                box-sizing: border-box;

                &:first-child {
                    padding-top: 2px;
                    border-bottom: 1px solid rgba(0, 0, 0, .2);
                }

                &:last-child {
                    padding-bottom: 2px;
                }
            }

            .vs {
                position: absolute;
                box-sizing: border-box;
                padding-right: 6px;
                font-size: 13px;
                left: -7px;
            }
        }
    }

    .meta {
        width: 20%;
    }
}
