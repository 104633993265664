@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIconsOutlined-Regular.otf') format('opentype'),
}

#root .splash-loader {
    transition: all 1s ease-in-out !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    visibility: visible;
    opacity: 1;
}

#root .splash-loader img {
    opacity: .68;
    animation: spin 2s linear infinite;
}

#root .splash-loader div {
    color: var(--content-color);
}

.mounted #root .splash-loader {
    visibility: hidden;
    opacity: 0;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.spinned {
      animation: spin 2s linear infinite;
  }

  @-moz-keyframes spin {
      from { -moz-transform: rotate(0deg); }
      to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
      from { -webkit-transform: rotate(0deg); }
      to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(360deg);}
  }
}

.offline-prompt {
    transition: all .5s ease-in-out;
    width: 220px;
    padding: 12px;
    box-sizing: border-box;
    position: absolute;
    top: 25vh;
    left: calc((100% - 220px)/2);
    z-index: 10;
    background: rgba(255, 255, 255, .9);
    border-radius: 12px;
    visibility: hidden;
    opacity: 0;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    p {
        margin-bottom: 1em;
        text-align: center;
        margin-top: 1em;
    }

    button {
        width: 100%;
    }
}

.app {
    height: 100vh;
    overflow: hidden;
    // min-width: 760px;
    background: var(--root-bg);
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all .3s ease-in-out;

    &.offline {
        filter: blur(1.2px);
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url('../patterns/noise.png');
        content: '';
        z-index: 1;
        mix-blend-mode: screen;
    }

    &>div {
        position: relative;
        z-index: 2;
    }

    .brand {
        width: 100%;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 601px) {
            position: fixed;
            top: 0;
            height: 50px;
            background: var(--root-bg);

            &.no_bg {
                background: none;

                img {
                    box-shadow: none;
                    background: none
                }
            }
        }

        img {
            height: 110px;

            @media screen and (max-width: 601px) {
                position: absolute;
                left: 0;
                height: 90px;
                padding-bottom: .5em;
                background: var(--root-bg);
                transition: all .5s linear;
                border-bottom-right-radius: 3em;
                box-shadow:
                    0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.07),
                    0 0.9375rem 1.40625rem rgba(90, 97, 105,  0.03),
                    0 0.25rem 0.53125rem rgba(90, 97, 105,  0.07),
                    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.02);
            }
        }

        &.transparent {

            img {
                @media screen and (max-width: 601px) {
                    box-shadow: none;
                    background: transparent;
                    height: 80px;
                    top: 5px;
                    left: 5px;
                }
            }
        }

        &.translated {

            img {
                transform: translateY(-120px);
            }
        }
    }

    .promo {
        text-align: center;
        padding-top:12px;

        img {
            height: 34px;
        }

        @media screen and (max-width: 601px) {
            width: 100%;
            box-sizing: border-box;
            padding: 1em;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .sponsorship {
        position: relative;
        margin: 0 auto;
        max-width: 600px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 0.5em;

        label {
            font-weight: 500;
            font-size: 10px;
            color: var(--content-color);
            margin-bottom: .25em;
        }

        img {

            &:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: 601px) {
            padding: 0.25em 1em;
        }
    }

    .container {
        width: 100%;
        max-width: 640px;
        position: relative;
        margin: 0 auto;
        transition: all .5s ease-in-out;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        max-height: unset;

        &.faded {

            &:not(.utility) {
                opacity: 0;
                visibility: hidden;
                transform: translateY(100vh);
                position: absolute;
            }

            &.utility {
                transform: translateY(-50vh);
                max-height: 0;
                opacity: 0;
            }
        }
    }

    .item-back {
        position: fixed;
        margin-top: -50px;
        margin-left: -540px;
        color: var(--content-color);

        &:not(div) {
            background: rgba(255, 255, 255, 0.07);
            border-color: rgba(255, 255, 255, 0.09);
        }

        @media screen and (max-width: 601px) {
            margin-left: calc(120px - 100%);
            font-size: 14px;
            line-height: 1.85rem;
            margin-top: -51px;
        }

        &>button {
            color: var(--content-color);
            background: rgba(255, 255, 255, 0.07);
            border-color: rgba(255, 255, 255, 0.09);

            @media screen and (max-width: 601px) {
                font-size: 14px;
                line-height: 1.85rem;
            }

            span {
                display: flex;
                align-items: center;
            }
        }
    }

    .content {
        display: flex;
        justify-content: center;
        height: calc(100vh - 260px);

        &.withItem {
            height: calc(100vh - 180px);
            margin-top: 10px;

            @media screen and (max-width: 601px) {
                height: calc(100vh - 110px);
                margin-top: 60px;
            }
        }

        &.withSponsor {
            height: calc(100vh - 300px);
            margin-top: 10px;

            @media screen and (max-width: 601px) {
                height: calc(100vh - 170px);
            }

            &.withItem {
                height: calc(100vh - 220px);

                @media screen and (max-width: 601px) {
                    height: calc(100vh - 130px);
                    margin-top: 60px;
                }
            }
        }

        @media screen and (max-width: 601px) {
            margin-top: 1em;
            height: calc(100vh - (152px + 1em));

        }

        .container {
            width: 100%;
            max-width: 640px;
            height: 100%;
            background: rgba(0, 0, 0, .47);
            border-radius: 9px;

            @media screen and (max-width: 601px) {
                margin: 0 .75em;
                max-width: calc(100% - 1.5em);
            }
        }

        .content-load-more {
            position: relative;
            width: calc(100% - 2em);
            margin-left: 1em;
            border-color: rgba(255, 255, 255, 0.04);
            background: rgba(255, 255, 255, 0.02);
            color: var(--content-color);
        }
    }

    .leagues {
        display: flex;
        justify-content: center;
        height: 30px;
        margin-bottom: 1em;
        align-items: center;

        @media screen and (max-width: 601px) {
            margin-bottom: .5em;
            height: 50px;
            padding-left: 120px;
            position: relative;
            padding-right: 1em;
        }

        .league-link {
            color: #fff;
            font-size: 18px;
            margin: 0 8px;
            opacity: .88;
            transition: all .3s ease-in-out;

            &.darken {
                color: var(--darken-color);
            }

            &.active {
                border-bottom: 1px dashed #fff;
                opacity: 1;

                &.darken {
                    border-bottom: 1px dashed var(--darken-color);
                }
            }

            &:hover {
                cursor: pointer;
                opacity: .6;
            }

            @media screen and (max-width: 601px) {
                font-size: 13px;

                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }

    .section-title {
        font-size: 20px;
        font-weight: 500;
        color: var(--content-color);
        margin-top: 1em;

        @media screen and (max-width: 601px) {
            margin-top: .5em;
            font-size: 16px;
        }
    }

    .section-grid {

        &.section-cards-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 12px;
            padding-top: 8px;

            @media screen and (max-width: 601px) {
                grid-template-columns: 1fr;
                grid-gap: 8px;
            }

            .section-grid-card {
                margin: 0;

                @media screen and (max-width: 601px) {
                    padding: .5em;
                }
            }
        }

        .section-grid-card {
            padding: 1em;
            border-radius: 6px;
            background: var(--root-bg);
            position: relative;
            box-sizing: border-box;
            margin-top: 1em;

            &.with-image {
                min-height: 106px;
                padding-left: 86px;

                .section-grid-card_img {
                    width: 72px;
                    height: 72px;
                    border-radius: 4px;
                    position: absolute;
                    top: -1em;
                    left: .5em;
                    box-sizing: border-box;
                    padding: 6px;
                    // background: rgba(0, 0, 0, .11);
                }
            }

            .section-grid-card_content {

                .section-grid-card_title {
                    color: var(--content-color);
                    margin-bottom: 2px;
                }

                .section-grid-card_tag {
                    border-radius: 12px;
                    display: inline-block;
                    padding: 1px 11px;
                    background: var(--backdrop-bg);
                    color: #fff;
                    font-size: 10px;
                }
            }
        }
    }
}
