.calendar {
    width: 100%;
    max-width: 640px;

    .calendar-body {

        .calendar-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em;

            @media screen and (max-width: 601px) {
                padding: 0.5em .35em 0;
            }

            .calendar-section_list {
                width: 100%;
            }
        }
    }
}
