.pills {
    display: flex;
    padding: 0 0 .6em;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 601px) {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    button {
        margin: 0 5px;

        @media screen and (max-width: 601px) {
            font-size: 12px;
            white-space: nowrap;
            margin-bottom: 5px;
        }
    }

    .splide {
        max-width: 640px;
    }

    .swipe-indicator {
        position: absolute;
        right: -34px;
        font-size: 23px;
        top: 2px;
        opacity: .7;
        color: var(--content-color);
    }

    &.dropdown {
        padding-left: 120px;
        padding-right: 1.5em;

        &>div[role="presentation"] {
            width: 100%;

            button {
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
                padding-right: 25px;

                span {
                    position: absolute;
                    top: 4px;
                    right: 5px;
                }
            }
        }
    }
}
