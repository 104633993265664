.container-nav {
    height: 48px;
    background: rgba(0, 0, 0, .31);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    box-sizing: border-box;

    button {
        margin: 0 6px;
        color: var(--content-color);

        span {
            margin-right: 4px;
        }

        &.active {
            background: rgba(255, 255, 255, .07);
            color: #fff;
            border-color: rgba(255, 255, 255, .09)
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        @media screen and (max-width: 601px) {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
            margin: 0 3px;

            .icons {
                font-size: 15px;
            }
        }

        @media screen and (max-width: 375px) {
            font-size: 11px;
        }
    }

    &.nested {
        background: rgba(0, 0, 0, 0.15);

        @media screen and (max-width: 375px) {
            font-size: 10.5px;
        }
    }
}
