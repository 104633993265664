.install {
    position: fixed !important;
    background: rgba(255, 255, 255, .94);
    left: 1em;
    right: 1em;
    bottom: 1em;
    z-index: 9 !important;
    border-radius: 1em;
    padding: 3em 1em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .5s ease-in-out;
    transform: translateY(75vh);

    &.shown {
        transform: translateY(0);
    }

    img {
        width: 98px;
        border-radius: 19px;
    }

    h5 {
        margin-top: 2em;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        line-height: 1.2;
    }

    div {
        margin-top: 2em;

        span {
            display: flex;
            width: 100%;
            height: 28px;
            align-items: center;

            i {
                margin-right: 12px;
            }
        }
    }

    button {
        width: 100%;
        border-radius: 17px;
        margin-top: 2.5em;

        &:last-child {
            margin-top: .75em;
            opacity: .5;
        }
    }

    &.ios {

        &::after {
            content:'';
            position: absolute;
            top: 100%;
            width: 0;
            height: 0;
            border-top: solid 10px rgba(255, 255, 255, .94);
            border-left: solid 15px transparent;
            border-right: solid 15px transparent;
        }

        button {
            opacity: .5;

            &:last-child {
                opacity: 1;
                display: block;
                line-height: 1.7;
                position: relative;
                padding-bottom: .5em;
            }
        }
    }
}
