.rankings {

    .rankings-table {
        padding-top: 1em;

        .rankings-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-direction: row;
            color: var(--content-color);
            min-height: 40px;
            box-sizing: border-box;
            padding: 0 1em;
            align-items: center;
            transition: all 0.4s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .68;
            }

            &:nth-child(even) {
                background: rgba(255, 255, 255, 0.02);
            }

            &>div {
                display: flex;
                align-items: center;
            }

            .name {
                width: 280px;
                box-sizing: border-box;
                padding-left: 1.25em;

                @media screen and (max-width: 601px) {
                    width: 190px;
                    padding-left: 1em;
                    font-size: 12px;
                }
            }

            .team {
                box-sizing: border-box;
                font-size: 11px;
                line-height: 1.1;
                opacity: .5;

                @media screen and (max-width: 601px) {
                    font-size: 10px;
                }
            }

            .pos {
                width: 24px;
                height: 24px;
                background: rgba(0, 0, 0, .15);
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, .09);
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
            }

            .spec {
                width: calc(100% - 304px);
                display: flex;
                justify-content: flex-end;

                @media screen and (max-width: 601px) {
                    width: calc(100% - 194px);
                }

                &>div {
                    width: 25%;
                    text-align: center;

                    &:nth-child(2) {
                        font-weight: 500;
                        color: #fff;
                    }
                }
            }

            &.head-row {

                .spec {
                    .a {
                        padding-right: 5px;
                        overflow: hidden;
                    }
                }

                @media screen and (max-width: 375px) {
                    font-size: .7rem;
                }
            }
        }
    }
}
