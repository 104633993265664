.loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--content-overlay);
    color: var(--brand);
    transition: all .7s ease-in-out;
    visibility: hidden;
    opacity: 0;
    border-radius: 9px;
    z-index: 99;

    span {
        font-size: 44px;
        animation: spin 2s linear infinite;
    }

    div {
        color: var(--content-color);
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
