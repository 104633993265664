.non-ideal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 320px;

    i {
        font-size: 58px;
        color: var(--content-color);
        opacity: .68;
    }

    div {
        margin-top: 30px;
        color: var(--content-color);
        max-width: 240px;
        text-align: center;
    }
}
