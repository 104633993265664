.stage-title {
    text-align: center;
    color: var(--content-color);
    padding: 8px;
}

.playoff-grid-banner {

    .desktop-banner {
        width: 100%;

        @media screen and (max-width: 601px) {
            display: none;
        }
    }

    .mobile-banner {
        display: none;

        @media screen and (max-width: 601px) {
            display: block;
            width: 100%;
        }
    }
}

.stage-group_title {
    text-align: center;

    button {
        background: rgba(255, 255, 255, 0.07);
        color: #fff;
        border-color: rgba(255, 255, 255, 0.09);

        @media screen and (max-width: 601px) {
            font-size: 12px;
        }
    }
}

.table {
    margin: 0.5em 1em;

    @media screen and (max-width: 601px) {
        margin: 0.5em;
    }

    .table-row {
        display: flex;
        height: 26px;
        align-items: center;
        color: var(--content-color);

        &:nth-child(even) {
            background: rgba(0, 0, 0, .11);
        }

        div.team {
            width: 240px;
            text-align: left;
            transition: all .5s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .68;
            }
        }

        div.team-compact {
            display: none;
        }

        div {
            width: calc((100% - 240px)/8);
            text-align: center;
        }

        div.double {
            width: calc((100% - 240px)/4);
        }

        @media screen and (max-width: 601px) {
            font-size: 12px;

            div.team {
                width: 150px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            div {
                width: calc((100% - 150px)/8);
                text-align: center;
            }

            div.double {
                width: calc((100% - 150px)/4);
            }
        }
    }

    &.chess {

        .table-row {
            div.team {
                width: 140px;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media screen and (max-width: 601px) {
                    width: 80px;
                }
            }

            @media screen and (max-width: 601px) {
                justify-content: space-between;
                font-size: 11px;

                div {
                    width: calc((100% - 200px)/8);
                }

                div.double {
                    display: none;
                }

                div.team {
                    display: none;
                }

                div.team-compact {
                    display: block;
                }
            }

            div.chess-row {
                display: flex;
                width: calc(100% - 180px);
                box-sizing: border-box;
                padding-left: 1em;
                height: 100%;

                @media screen and (max-width: 601px) {
                    width: calc(100% - 60px);
                }

                &.chess-top {

                    .chess-cell {
                        border: none;
                        font-size: 14px;

                        @media screen and (max-width: 601px) {
                            font-size: 12px;
                        }
                    }
                }

                .chess-cell {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 11px;
                    border: 1px solid rgba(255, 255, 255, .15);
                    border-bottom: none;

                    @media screen and (max-width: 601px) {
                        font-size: 10px;
                    }

                    span {
                        width: 100%;
                        transition: all .3s ease-in-out;

                        &:hover {
                            cursor: pointer;
                            opacity: .78;
                        }
                    }
                }

                .chess-cell.self {
                    background: var(--backdrop-bg);
                    opacity: .58;
                }
            }

            &:last-child {

                .chess-row {
                    .chess-cell {
                        border-bottom: 1px solid rgba(255, 255, 255, .15);
                    }
                }
            }
        }
    }
}
