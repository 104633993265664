.promo-block {
    width: 640px;
    position: relative;
    margin: 10px auto;

    @media screen and (max-width: 601px) {
        width: 100%;
        margin-top: 7px;
    }

    &.grid {
        display: grid;
        box-sizing: border-box;

        @media screen and (max-width: 601px) {
            padding: 1em;
            padding-top: 0;
        }

        .promo-item {
            border-radius: 8px;
            transition: all 0.4s ease-in-out;
            overflow: hidden;
            background: #fff;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
                opacity: .85;
            }
        }

        &.g3 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 14px;

            @media screen and (max-width: 601px) {
                grid-gap: 7px;
            }
        }

        .promo-item {

            img {
                max-width: 100%;
                max-height: 50px;
            }
        }
    }
}
