.player {
    border-radius: 9px;

    .player-top {
        position: relative;
        height: 30vh;
        background: var(--content-overlay);
        border-radius: 9px;
        display: flex;

        @media screen and (max-width: 601px) {
            height: 25vh;
        }

        .player-info {
            width: 50%;
            height: 100%;
            box-sizing: border-box;
            position: relative;
            padding: 2em 1em 1em 3em;
            z-index: 2;

            @media screen and (max-width: 601px) {
                width: 60%;
                padding: 1em .5em .5em 1em;
                overflow: hidden;
            }

            .name {
                color: var(--content-color);
                font-size: 42px;
                line-height: 1.1;
                font-weight: 500;

                @media screen and (max-width: 601px) {
                    font-size: 22px;
                }
            }

            .meta {
                color: var(--content-color);
                opacity: .72;
                margin-top: 4px;

                @media screen and (max-width: 601px) {
                    font-size: 12px;
                }
            }

            .stats {
                position: absolute;
                left: 1.5em;
                bottom: 0;
                right: 0;

                @media screen and (max-width: 601px) {
                    left: -1em;
                }

                .primary-label {
                    font-style: normal;
                    position: absolute;
                    top: 0;
                    background: var(--root-bg);
                    color: #fff;
                    transform: rotate(33deg);
                    transform-origin: top left;
                    padding: 3px 12px;
                    left: 90px;
                    top: 10px;
                    z-index: 2;

                    @media screen and (max-width: 601px) {
                        left: 70px;
                        top: -2px;
                        font-size: 12px;
                    }
                }

                .primary {
                    width: 200px;
                    height: 100px;
                    background: var(--backdrop-bg);
                    clip-path: polygon(24% 0, 0% 100%, 100% 100%);
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    box-sizing: border-box;
                    padding-right: 6em;

                    @media screen and (max-width: 601px) {
                        width: 160px;
                        height: 80px;
                        padding-right: 4em;
                    }

                    i {
                        color: #fff;
                        font-size: 28px;
                        padding-bottom: 20px;

                        @media screen and (max-width: 601px) {
                            font-size: 20px;
                            padding-bottom: 14px;
                        }
                    }

                    span {
                        color: #fff;
                        font-size: 50px;
                        font-weight: 500;

                        @media screen and (max-width: 601px) {
                            font-size: 38px;
                        }
                    }
                }

                .rest {
                    position: absolute;
                    right: 20px;
                    bottom: 1em;

                    .position {
                        color: var(--backdrop-bg);
                        font-size: 40px;
                        font-weight: 500;
                        text-align: right;

                        @media screen and (max-width: 601px) {
                            font-size: 23px;
                            line-height: 1.1;
                        }
                    }

                    @media screen and (max-width: 601px) {
                        bottom: 0.5em;
                    }

                    .row {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: var(--content-color);

                        @media screen and (max-width: 601px) {
                            font-size: 12px;
                        }

                        div:first-child {
                            opacity: .58;
                        }

                        div:last-child {
                            width: 30px;
                            text-align: right;
                            font-weight: 500;

                            @media screen and (max-width: 601px) {
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }

        .player-photo {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50%;
            top: -100px;
            overflow: hidden;
            box-sizing: border-box;
            padding-right: 1em;

            @media screen and (max-width: 601px) {
                display: flex;
                align-items: flex-start;
                padding-right: 0;
                top: -75px;
            }

            &.holder {
                img {
                    opacity: .06;
                }
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                height: 80px;
                background: linear-gradient(to top, var(--content-overlay), 80%, transparent);
            }

            &::after {
                content: '';
                left: 0;
                width: 5em;
                bottom: 0;
                top: 100px;
                background: #ccc;
                position: absolute;
                background: linear-gradient(to right, var(--content-overlay), 80%, transparent);

                @media screen and (max-width: 601px) {
                    width: 2.5em;
                    top: 75px;
                }
            }
        }
    }

    .player-content {
        padding: 1em 2em;

        @media screen and (max-width: 601px) {
            padding: 0em 0.75em;
        }

        .player-section {

            .section-grid-card_tag {
                margin-bottom: 2em;
            }

            .season-stats {
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding-left: 1em;
                height: 26px;
                align-items: center;
                background: rgba(0, 0, 0, .11);
                color: var(--content-color);

                .season-stats-value {
                    width: calc(100%/6);
                    text-align: center;

                    span {
                        position: relative;
                    }

                    span::before {
                        content: '';
                        display: block;
                        width: 17px;
                        height: 17px;
                        background-size: 17px;
                        position: absolute;
                        left: -22px;
                        top: 0px;
                        opacity: .5;

                        @media screen and (max-width: 601px) {
                            width: 13px;
                            height: 13px;
                            background-size: 13px;
                            left: -15px;
                            top: 0px;
                        }
                    }

                    &.pld span::before {
                        background-image: url('../../assets/img/icons/kick-off.png');
                    }

                    &.goal span::before {
                        background-image: url('../../assets/img/icons/goal.png');
                    }

                    &.assist span::before {
                        background-image: url('../../assets/img/icons/assist.png');
                    }

                    &.mvp span::before {
                        background-image: url('../../assets/img/icons/mvp.png');
                    }

                    &.yc span::before {
                        background-image: url('../../assets/img/icons/yc.png');
                    }

                    &.rc span::before {
                        background-image: url('../../assets/img/icons/rc.png');
                    }
                }
            }
        }
    }
}
